import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Intro from './components/Intro';
import Works from './components/Works';
import Contact from './components/Contact';
import About from './components/About';
import LetsCreate from './components/LetsCreate';
import Services from './components/Services';

const App: React.FC = () => {
  return (
    <div id="scroll-container">
      <Navbar />
      <div id="content-wrap">
        <Intro />
        <div id="page-content">
          <About />
          <Works />
          <Services />
          <LetsCreate/>
          <Contact />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
