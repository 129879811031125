const About: React.FC = () => {
    return (
        <div className="tt-section padding-bottom-xlg-150">
        <div className="tt-section-inner tt-wrap max-width-900">
            <div className="text-xxlg font-alter text-gray-2">
                <div className="ph-caption">
                    <h1 className="ph-caption-title ph-appear">WHO ARE WE?</h1>
                    <div className="ph-caption-title-ghost ph-appear">ABOUT US</div>
                </div>
                <p className="anim-fadeinup">We are an <strong className="text-white">African brand</strong>. of universal
                    inspiration, specializing in publishing,
                    design, and the production of furniture
                    and accessories.</p>
                <p className="anim-fadeinup">We are a brand that brings together
                    talents—a symbol of unity.
                    Through our services and solutions,
                    we reveal a new way of living
                    and seeing things.</p>
            </div>
            <div className="text-xxlg font-alter text-gray-2 margin-top-60">
                <div className="ph-caption">
                    <h1 className="ph-caption-title ph-appear">OUR BELIEF?</h1>
                </div>
                <p className="anim-fadeinup">Your living space reflects the beauty of your soul.
                    Human nature is beautiful and deserves to be
                    conveyed in your home.
                    Inspire poetry in your interior.
                    Opt for pieces of heritage quality—pieces
                    that have a story to tell.</p>
                <p className="anim-fadeinup">So, what is the object in your home that you
                    want to see last a lifetime?</p>
                <div className="ph-caption-subtitle ph-appear" style={{textDecoration:'underline'}}>Our eco commitment:</div>
                <p className="anim-fadeinup">Our interior and furniture design projects are fully
                    committed to sustainable practices.
                    We use recycled, durable materials
                    while minimizing waste and employing
                    environmentally friendly production methods.</p>
            </div>
        </div>
    </div>

    );
};

export default About;
