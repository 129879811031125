const Services: React.FC = () => {
    return (
        <div className="tt-section">
            <div className="tt-section-inner tt-wrap">
                <div className="tt-row">
                    <div className="tt-col-lg-4 padding-right-lg-5-p">
                        <div className="tt-heading tt-heading-sm margin-bottom-60 anim-fadeinup">
                            <h2 className="tt-heading-title text-gray">FURNITURE
                                <br className="hide-from-md" /> SERVICES</h2>
                        </div>
                    </div>
                    <div className="tt-col-lg-8">
                        <div className="text-xxlg font-alter">
                            <p className="anim-fadeinup">We offer a variety of services to clients.</p>
                            <p className="anim-fadeinup">Custom Furniture Design and manufacturing:<br/> we work closely with clients
                                to create unique and personalized furniture pieces tailored to your specific
                                needs and preferences. This involves conceptualizing designs, creating
                                technical drawings, and producing prototypes before final production
                                We can handle the production of furniture based on design specifications,
                                ensuring high-quality craftsmanship and attention to detail.
                                Furniture Restoration and Refinishing: We can repair damaged pieces,
                                refinish surfaces, and bring new life to old furniture through careful
                                restoration techniques.
                                Material and Finish Selection: our Furniture designers can assist you in
                                selecting the right materials and finishes for your furniture.
                                They have knowledge of different wood types, upholstery fabrics,
                                metal finishes, and other materials, helping you choose options that
                                align with their desired style, durability requirements, and budget.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default Services;
