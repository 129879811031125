import { useEffect } from "react";

const LetsCreate: React.FC = () => {
    useEffect(() => {
        document.querySelectorAll('.tt-scrolling-text').forEach((element) => {
            const scrollSpeed = element.getAttribute('data-scroll-speed');
            const innerElement = element.querySelector('.tt-scrolling-text-inner') as HTMLElement;
            if (innerElement) {
                innerElement.style.animationDuration = `${scrollSpeed}s`;
            }
        });
    }, []);
    return (
        <div className="tt-section padding-top-xlg-120 padding-bottom-xlg-150 bg-letscreate">
            <div className="tt-section-inner">
                <div className="tt-scrolling-text scr-text-stroke font-italic anim-fadeinup" data-scroll-speed={15}>
                    <div className="tt-scrolling-text-inner" style={{ color: "white" }} data-text="INTERIOR DESIGN SERVICES! -">
                        INTERIOR DESIGN SERVICES! -
                    </div>
                </div>
                <div className="tt-scrolling-text scr-text-stroke scr-text-reverse font-italic anim-fadeinup" data-scroll-speed={15}>
                    <div className="tt-scrolling-text-inner" style={{ color: "white" }} data-text="FURNITURE SERVICES! -">
                        FURNITURE SERVICES! -
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LetsCreate;
