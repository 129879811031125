const Intro: React.FC = () => {
    return (
        <div id="page-header" className="ph-full ph-cap-sm ph-image-cropped ph-image-cover-2 ph-content-parallax">
            <div className="page-header-inner tt-wrap">
                <div className="ph-image">
                    <div className="ph-image-inner" style={{ backgroundColor: 'rgba(102, 102, 102, 0.8)', borderRadius: '24px', marginLeft: '15px' }}>
                        <img src="assets/img/maison/projects/2.png" style={{ padding: '25px' }} alt="Image" />
                    </div>
                </div>
                <div className="ph-caption max-width-1000">
                    <h1 className="ph-caption-title ph-appear font-normal float text-shadow">
                        <em>Your living space</em> reflects <br />the beauty of your <em><u>soul</u></em>
                    </h1>
                </div>

            </div>
            <a href="#page-content" className="scroll-down-circle" data-offset="30">
                <div className="sdc-inner ph-appear">
                    <div className="sdc-icon"><i className="fas fa-chevron-down"></i></div>
                    <svg viewBox="0 0 500 500">
                        <defs>
                            <path d="M50,250c0-110.5,89.5-200,200-200s200,89.5,200,200s-89.5,200-200,200S50,360.5,50,250" id="textcircle"></path>
                        </defs>
                        <text dy="30">
                            <textPath xlinkHref="#textcircle">Scroll down - Scroll down -</textPath>
                        </text>
                    </svg>
                </div>
            </a>
            <div className="made-with-love ph-appear">
                <div className="mwl-inner">
                    <div className="mwl-text">A symbol of unity</div>
                    <div className="mwl-icon"><i className="far fa-heart"></i></div>
                </div>
            </div>
        </div>
    );
};

export default Intro;
