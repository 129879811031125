const Contact: React.FC = () => {
    return (
        <div id="contact" className="tt-section padding-top-xlg-150 padding-bottom-xlg-150 padding-left-sm-3-p padding-right-sm-3-p bg-white-accent-3">
            <div className="tt-section-inner tt-wrap">
                <div className="tt-row">
                    <div className="tt-col-lg-5 padding-right-lg-5-p">
                        <div className="tt-heading tt-heading-lg margin-bottom-10-p anim-fadeinup">
                            <h3 className="tt-heading-subtitle">Get in Touch</h3>
                            <h2 className="tt-heading-title">Drop Us<br /> A Line</h2>
                        </div>
                        <ul className="tt-contact-info padding-bottom-40">
                            <li className="anim-fadeinup">
                                <span className="tt-ci-icon"><i className="fas fa-map-marker-alt" /></span>
                                121 King Street, Melbourne, Australia
                            </li>
                            <li className="anim-fadeinup">
                                <span className="tt-ci-icon"><i className="fas fa-phone" /></span>
                                <a href="tel:+123456789000" className="tt-link">+(123) 456 789 000</a>
                            </li>
                            <li className="anim-fadeinup">
                                <span className="tt-ci-icon"><i className="fas fa-envelope" /></span>
                                <a href="mailto:company@email.com" className="tt-link">company@email.com</a>
                            </li>
                        </ul>
                    </div>
                    <div className="tt-col-lg-7">
                        <form id="tt-contact-form" className="tt-form-lg tt-form-minimal tt-hide-labels anim-fadeinup">
                            <input type="hidden" name="project_name" defaultValue="yourwebsiteaddress.com" />
                            <input type="hidden" name="admin_email" defaultValue="your@email.com" />
                            <input type="hidden" name="form_subject" defaultValue="Message from yourwebsiteaddress.com" />
                            <div className="tt-row">
                                <div className="tt-col-md-6">
                                    <div className="tt-form-group">
                                        <label>Name</label>
                                        <input className="tt-form-control" type="text" name="Name" placeholder="Enter your name" required />
                                    </div>
                                </div>
                                <div className="tt-col-md-6">
                                    <div className="tt-form-group">
                                        <label>Email address</label>
                                        <input className="tt-form-control" type="email" name="Email" placeholder="Enter your email" required />
                                    </div>
                                </div>
                            </div>
                            <div className="tt-form-group">
                                <label>Subject</label>
                                <input className="tt-form-control" type="text" name="Subject" placeholder="Subject" required />
                            </div>
                            <div className="tt-form-group">
                                <label>Message</label>
                                <textarea className="tt-form-control" rows={7} name="Message" placeholder="What's in your mind?" required defaultValue={""} />
                                <small className="tt-form-text"><em>* All fields are required!</em></small>
                            </div>
                            <div className="tt-btn tt-btn-light-outline">
                                <button type="submit" data-hover="Send Message">Send Message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Contact;
