import React, { useState, useEffect } from 'react';
import { gsap, TimelineMax, Power2 } from 'gsap';

const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const htmlElement = document.querySelector('html');
        const bodyElement = document.querySelector('body');
        if (isOpen) {
            htmlElement?.classList.add('tt-no-scroll');
            bodyElement?.classList.add('tt-ol-menu-open');
            openMenu();
        } else {
            htmlElement?.classList.remove('tt-no-scroll');
            bodyElement?.classList.remove('tt-ol-menu-open');
            closeMenu();
        }
    }, [isOpen]);

    const openMenu = () => {
        const bodyElement = document.querySelector('body');
        bodyElement?.classList.add('olm-toggle-no-click');

        const tl_olMenuIn = new TimelineMax({
            onComplete: () => {
                bodyElement?.classList.remove('olm-toggle-no-click');
            },
        });

        tl_olMenuIn.to('.tt-overlay-menu', { duration: 0.4, autoAlpha: 1 })
            .from('.tt-ol-menu-list > li', { duration: 0.4, y: 80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeOut, clearProps: 'all' });

        if (document.querySelector('.tt-ol-menu-anim')) {
            tl_olMenuIn.from('.tt-ol-menu-anim', { duration: 0.4, y: 60, autoAlpha: 0, stagger: 0.05, ease: Power2.easeOut, clearProps: 'all' }, '-=0.4');
        }

        document.querySelectorAll('.tt-overlay-menu a, .tt-logo a').forEach(item => {
            item.addEventListener('click', handleMenuLinkClick);
        });
    };

    const closeMenu = () => {
        const bodyElement = document.querySelector('body');
        bodyElement?.classList.add('olm-toggle-no-click');

        const tl_olMenuOut = new TimelineMax({
            onComplete: () => {
                bodyElement?.classList.remove('olm-toggle-no-click');
                setIsOpen(false);
                gsap.set('#content-wrap, .ttgr-cat-nav', { autoAlpha: 1 }); // Restore visibility
            },
        });

        tl_olMenuOut.to('.tt-ol-menu-list > li', { duration: 0.4, y: -80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeIn })
            .to('.tt-ol-menu-anim', { duration: 0.4, y: -60, autoAlpha: 0, stagger: 0.05, ease: Power2.easeIn }, '-=0.4')
            .to('.tt-overlay-menu', { duration: 0.4, autoAlpha: 0, clearProps: 'all' }, '+=0.2')
            .set('.tt-ol-menu-list > li, .tt-ol-menu-anim', { clearProps: 'all' });

        setTimeout(() => {
            document.querySelectorAll('.tt-ol-submenu').forEach(submenu => {
                (submenu as HTMLElement).style.display = 'none';
            });
        }, 500);
    };

    const handleMenuLinkClick = (e: Event) => {
        e.preventDefault();
        gsap.set('#content-wrap, .ttgr-cat-nav', { autoAlpha: 0 });
    };

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const handleHoverEnter = () => {
        const menuList = document.querySelector('.tt-ol-menu-list') as HTMLElement;
        menuList.classList.add('tt-ol-menu-hover');
    };

    const handleHoverLeave = () => {
        const menuList = document.querySelector('.tt-ol-menu-list') as HTMLElement;
        menuList.classList.remove('tt-ol-menu-hover');
    };

    const handleSubMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const target = event.currentTarget as HTMLElement;
        const submenu = target.nextElementSibling as HTMLElement;

        if (submenu) {
            document.querySelectorAll('.tt-ol-submenu').forEach(sub => {
                if (sub !== submenu) {
                    (sub as HTMLElement).style.display = 'none';
                }
            });
            submenu.style.display = submenu.style.display === 'block' ? 'none' : 'block';
        }
    };

    return (
        <header id="tt-header" className="tt-header">
            <div className="tt-header-inner">
                <div className="tt-header-col">
                    <div id="tt-ol-menu-toggle-btn-wrap">
                        <div className="tt-ol-menu-toggle-btn-holder">
                            <a href="#" className="tt-ol-menu-toggle-btn magnetic-item" onClick={toggleNavbar}>
                                <span></span>
                            </a>
                        </div>
                        <div className="ptr-prel-content">
                            <a href='/'><img src="assets/img/maison/logos/logolight.png" className="ptr-prel-image-navbar" alt="Logo" /></a>
                        </div>
                    </div>
                    <nav className="tt-overlay-menu tt-ol-menu-center tt-ol-menu-count">
                        <div className="tt-ol-menu-holder">
                            <div className="tt-ol-menu-inner tt-wrap">
                                <div className="tt-ol-menu-content">
                                    <ul className="tt-ol-menu-list" onMouseEnter={handleHoverEnter} onMouseLeave={handleHoverLeave}>
                                        <li><a href="/">Home</a></li>
                                        <li className="tt-ol-submenu-wrap">
                                            <div className="tt-ol-submenu-trigger" onClick={handleSubMenuClick}>
                                                <a href="#">Services</a>
                                                <div className="tt-ol-submenu-caret-wrap">
                                                    <div className="tt-ol-submenu-caret magnetic-item"></div>
                                                </div>
                                            </div>
                                            <div className="tt-ol-submenu">
                                                <ul className="tt-ol-submenu-list">
                                                    <li><a href="landing-page-1.html">Furniture Services</a></li>
                                                    <li><a href="landing-page-2.html">Interior Design Services</a></li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li><a href="contact.html">Works</a></li>
                                        <li><a href="contact.html">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Navbar;
