import React, { useEffect } from 'react';
import worksData from '../db.json';

interface Work {
    id: number;
    title: string;
    design: string;
    image: string;
    link: string;
    scrollSpeed: number;
}

const Works: React.FC = () => {
    const works: Work[] = worksData.works;

    return (
        <div className="tt-section padding-bottom-xlg-150">
            <div className="tt-section-inner tt-wrap">
                <div className="tt-heading tt-heading-center tt-heading-lg margin-bottom-10-p anim-fadeinup">
                    <h3 className="tt-heading-subtitle">Latest Projects</h3>
                    <h2 className="tt-heading-title">Works</h2>
                    <div className="tt-btn tt-btn-link text-gray">
                        <a href="#works" data-hover="See All Works">See All Works</a>
                    </div>
                </div>
                <div id="portfolio-grid" className="pgi-cap-inside pgi-cap-hover">
                    <div className="tt-grid ttgr-layout-1-2 ttgr-gap-4">
                        <div className="tt-grid-items-wrap isotope-items-wrap">
                            {works.map(work => (
                                <div key={work.id} className="tt-grid-item isotope-item">
                                    <div className="ttgr-item-inner">
                                        <div className="portfolio-grid-item">
                                            <a href={work.link} className="pgi-image-wrap" data-cursor={`<figure className='pi-item-image cover-opacity-2'><img src=${work.image} alt='image' /></figure>`}>
                                                <div className="pgi-image-holder cover-opacity-2">
                                                    <div className="pgi-image-inner anim-zoomin">
                                                        <figure className="pgi-image ttgr-height">
                                                            <img src={work.image} alt={work.title} />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="pgi-caption">
                                                <div className="pgi-caption-inner">
                                                    <h2 className="pgi-title">{work.title}</h2>
                                                    <div className="pgi-categories-wrap">
                                                        <div className="pgi-category">{work.design}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Works;
